import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2F39-scaled-compressed.jpg?alt=media&token=c456451b-72bb-452a-9488-d12b4cf6786e")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2Fresto-hero02.jpg?alt=media&token=8986c597-ed97-4a9c-8e63-2e8493085a1d")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2Fresto-hero04.jpg?alt=media&token=30bb3dda-506a-42f5-b27c-713d71675f6d")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/redes%20y%20directorios%2F39-scaled-compressed.jpg?alt=media&token=c456451b-72bb-452a-9488-d12b4cf6786e")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto h-[100vh]">
          <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
